import React from 'react';
import { PathService } from '../../services';
import { OurInsightsAnalysisViewContext, TemplateProps } from '../../types';
import { OurInsightsAnalysisView } from '../../views';

const OurInsightsAnalysis: React.FunctionComponent<TemplateProps<
  OurInsightsAnalysisViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurInsightsAnalysisView
      briefings={JSON.parse(pageContext.briefings)}
      perspectivesInCrime={JSON.parse(pageContext.perspectivesInCrime)}
      infographics={JSON.parse(pageContext.infographics)}
    />
  );
};

export default OurInsightsAnalysis;
